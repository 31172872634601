<template>
<!-- 我的空间和文件目录公用显示组件 -->
  <div class="folder-panel" :class="{'paddingBottom': checkedLen>0}">
    <div class="top-panel">
      <div class="path" :class="{'more': catalogTree.length===1}">
        <span v-for="(item,index) in catalogTree" :key="index">
          {{item}}
          <svg-icon icon-class="right" style="font-size:12px" v-if="index<catalogTree.length-1"></svg-icon>
        </span>
      </div>
    </div>
    <div class="list-main" v-if="listData.length>0">
      <!-- <span class="checkbox" @click="checkAllSelected">
        <span class="checkbox-inner" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'':'somecheck')"></span>
      </span> -->
      <div class="flexJK">
        <span class="file-name">名称</span>
        <span class="gd"></span>
        <span class="file-owner">所有者</span>
        <span class="gd"></span>
        <span class="file-updatetime">剩余时间</span>
        <span class="operate-btn">
          <svg-icon icon-class="list-view" v-if="nowType==='card'"></svg-icon>
          <svg-icon icon-class="card-view" v-if="nowType==='list'"></svg-icon>
        </span>
      </div>
    </div>
    <ul class="list-container" v-if="listData.length>0">
      <li class="list-main" v-for="(item, index) in listData" :key="item.id">
        <span class="checkbox" @click="checkSelected(item, index)">
            <span class="checkbox-inner" :class="item.checked?'allcheck':''"></span>
          </span>
        <a style="display:flex;flex: 1 1 0%; flex-direction: row">
          <span class="file-name">
            <span class="icon">
              <template v-if="item.hasArticle === 1">
                <svg-icon v-if="!item.type" icon-class="markdown_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newExcel'" icon-class="excel_icon"></svg-icon>
                <svg-icon v-else-if="item.type==='newPowerPoint'" icon-class="ppt"></svg-icon>
                <svg-icon v-else-if="item.type==='newMp4'" icon-class="mp4"></svg-icon>
                <svg-icon v-else-if="item.type==='newDoc'" icon-class="word"></svg-icon>
                <svg-icon v-else icon-class="word"></svg-icon>
              </template>
              <svg-icon v-else icon-class="folder_icon"></svg-icon>
            </span>
            <span class="name">{{item.name}}</span>
          </span>
          <span class="gd"></span>
          <span class="file-owner">{{item.username}}</span>
          <span class="file-updatetime">{{item.remainingTime }}</span>
        </a>
        <span class="operate-btn">
          <right-operate :treeData="item" @recoverSuccess="recoverSuccess"></right-operate>
        </span>
      </li>
    </ul>
    <div v-if="listData.length===0" class="noData">
      <img src="../../../../public/static/img/trash.png" alt="">
      <p>回收站暂无内容</p>
    </div>
    <div class="bottomFixed" v-show="checkedLen>0">
      <div class="list-main">
        <span class="checkbox" @click="checkAllSelected">
          <span class="checkbox-inner" style="margin-top:8px;" :class="checkedLen===listData.length?'allcheck':(checkedLen===0?'':'somecheck')"></span>
        </span>
        <el-button @click="recoverBatch" :disabled="checkedLen < 1" type="primary" size="small" plain style="height:32px;margin-left:15px">恢复</el-button>
        <el-button @click="deleteBtn" :disabled="checkedLen < 1" type="danger" size="small" plain style="height:32px">彻底删除</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import rightOperate from './rightOperate'
import { selectRecycleList, recoverSpace, deleteSpace } from '@/api/space'
import { deleteFile } from '@/api/fileApi'

export default {
  components: { rightOperate },
  name: 'folderView',
  data() {
    return {
      nowType: 'list',
      listData: [],
      checkedLen: 0,
      encryptionStr: '',
      deleteIdArr: []
    }
  },
  computed: {
    catalogTree() {
      if (this.$store.getters.catalogTree.length === 0) {
        return ['回收站']
      } else {
        return this.$store.getters.catalogTree
      }
    }
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        this.encryptionStr = this.$route.fullPath === '/drive/trash' ? '-1' : this.$route.params.pathMatch
        this.getChapterList()
      }
    }
  },
  methods: {
    getChapterList() {
      this.checkedLen = 0
      selectRecycleList({
        encryptionStr: this.encryptionStr,
        region: this.encryptionStr === '-1' ? 'recycle' : 'my'
      }).then((res) => {
        if (res.code === '000000' && res.data) {
          this.listData = res.data
          this.deleteIdArr = res.deleteIdArr
          if (this.deleteIdArr.length > 0) {
            this.searchDelete()
          }
        } else {
          this.listData = []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkSelected(item, index) {
      this.$nextTick(() => {
        // this.listData[index].checked = !this.listData[index].checked
        item.checked = !item.checked
        this.checkedLen = this.listData.filter(i => i.checked).length
        console.log(item)
      })
    },
    checkAllSelected() {
      const len = this.listData.filter(i => i.checked).length
      const allLen = this.listData.length
      if (len === allLen) {
        this.checkedLen = 0
        this.listData.forEach(i => { i.checked = false })
      } else {
        this.checkedLen = this.listData.length
        this.listData.forEach(i => { i.checked = true })
      }
    },
    recoverSuccess() {
      this.getChapterList()
    },
    recoverBatch() {
      this.$confirm('是否要恢复该章节?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const idList = this.listData.filter(item => item.checked).map(item => item.id)
          recoverSpace({ idList: idList }).then(response => {
            if (response.code === '000000') {
              this.$message.success(response.message)
              this.getChapterList()
            } else {
              this.$message.error(response.message)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消恢复操作'
          })
        })
    },
    deleteBtn() {
      this.$confirm('是否要彻底删除该节点?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const strList = this.listData.filter(item => item.checked).map(item => item.id)
          deleteSpace({ id: strList }).then(response => {
            if (response && response.data.length > 0) {
              const Base64 = require('js-base64').Base64
              const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
              deleteFile({
                action: 'deleteFiles',
                code: urlCode
              }, { rids: response.data.map(item => item.dpath) }).then(response => {
                this.$message.success('删除成功')
                this.getChapterList()
              }).catch(err => {
                console.log(err)
              })
            } else {
              this.$message.success('删除成功')
              this.getChapterList()
            }
          }).catch(err => {
            console.log(err)
          })
        }).catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除操作'
          })
        })
    },
    searchDelete() {
      deleteSpace({ id: this.deleteIdArr }).then(response => {
        if (response && response.data.length > 0) {
          const Base64 = require('js-base64').Base64
          const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
          deleteFile({
            action: 'deleteFiles',
            code: urlCode
          }, { rids: response.data.map(item => item.dpath) }).then(response => {
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.noData {
  text-align: center;
  padding-top: 150px;
}
.folder-panel {
  position: relative;
  font-size: 14px;
  color: #808183;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.paddingBottom {
    padding-bottom: 40px;
  }
  .top-panel {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    padding-bottom: 10px;
    .path {
      font-size: 18px;
      color: #38383a;
      display: flex;
      &.more {
        font-weight: bold;
      }
    }
    .right-btn {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
}

.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 45px;
      margin-right: 10px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.list-main {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #d8dadb;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #f4f6f7;
  }
  .checkbox {
    width: 24px;
    display: flex;
    flex: 0 0 auto;
  }
  .gd {
    width: 10px;
  }
  .file-name {
    display: flex;
    flex-grow: 8;
    flex-shrink: 1;
    flex-basis: 100px;
    padding-left: 0;
    .icon {
      display: block;
      width: 30px;
      height: 30px;
      .svg-icon {
        width: 1.4em;
        height: 1.4em;
        margin-top: 12px;
      }
    }
    .name {
      display: flex;
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      max-width: 500px;
    }
  }
  .file-owner {
    display: flex;
    flex-grow: 6;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .file-updatetime {
    display: flex;
    flex-grow: 4;
    flex-shrink: 1;
    padding-left: 0;
    flex-basis: 100px;
  }
  .operate-btn {
    display: flex;
    flex: 0 0 auto;
    padding: 0 12px;
    .svg-icon {
      width: 1.2em;
      height: 1.2em;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}
.list-container {
  flex: auto;
  height: calc(100vh - 170px);
  overflow-y: auto;
  .list-main {
    height: 50px;
    line-height: 50px;
    a {
      color: #808183;
    }
    &:hover {
      a {
        color: #409eff;
      }
    }
  }
  .checkbox-inner {
    margin-top: 15px;
  }
  .operate-btn {
    cursor: pointer;
    span {
      padding-top: 5px;
    }
    .svg-icon {
      margin-top: 16px !important;
    }
  }
}
.bottomFixed {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: calc(100% - 260px);
  .list-main {
    height: 50px;
    border-top: 1px solid #d8dadb;
    border-bottom: 0 none;
    padding: 8px 0;
  }
}
.checkbox-inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-top: 12px;
  background-color: #fff;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
  &:hover {
    border-color: #409eff;
    cursor: pointer;
  }
  &::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
  }
  // 全选
  &.allcheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      transform: rotate(45deg) scaleY(1);
    }
  }
  // 未全选
  &.somecheck {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: #fff;
      height: 2px;
      width: 12px;
      transform: scale(.5);
      left: 0;
      right: 0;
      top: 5px;
    }
  }
}
.flexJK {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
}
</style>
