<template>
  <el-popover
    placement="right-start"
    width="180"
    trigger="hover">
    <div class="right-panel">
      <ul class="operate-list">
        <li class="operate-item" @click="recoverBtn">
          <span class="icon">
            <svg-icon icon-class="recover"></svg-icon>
          </span>
          <span class="operate">恢复</span>
        </li>
        <li class="operate-item" @click="deleteBtn">
          <span class="icon">
            <svg-icon icon-class="recycle"></svg-icon>
          </span>
          <span class="operate">彻底删除</span>
        </li>
        <!-- <li class="operate-item">
          <el-button type="text"
            v-clipboard:copy="`http://www.baidu.com`">
            复制链接
          </el-button>
        </li> -->
      </ul>
    </div>
    <span slot="reference" @click.stop="">
      <svg-icon icon-class="ellipsis"></svg-icon>
    </span>
  </el-popover>
</template>
<script>
import { recoverSpace, deleteSpace } from '@/api/space'
import { deleteFile } from '@/api/fileApi'

export default {
  data() {
    return {

    }
  },
  props: {
    treeData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    recoverBtn() {
      this.$confirm('是否要恢复该章节?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          recoverSpace({ idList: [this.treeData.id] }).then(response => {
            if (response.code === '000000') {
              this.$message.success(response.message)
              this.$emit('recoverSuccess')
            } else {
              this.$message.error(response.message)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消恢复操作'
          })
        })
    },
    deleteBtn() {
      this.$confirm('是否要彻底删除该章节?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteSpace({ id: [this.treeData.id] }).then(response => {
            if (response.code === '000000') {
              if (response && response.data.length > 0) {
                const Base64 = require('js-base64').Base64
                const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
                deleteFile({
                  action: 'deleteFiles',
                  code: urlCode
                }, { rids: response.data.map(item => item.dpath) }).then(res => {
                  this.$message.success('删除成功')
                  this.$emit('recoverSuccess')
                }).catch(err => {
                  console.log(err)
                })
              } else {
                this.$message.success(response.message)
                this.$emit('recoverSuccess')
              }
            } else {
              this.$message.error(response.message)
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消删除操作'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 40px;
      margin-right: 5px;
      .svg-icon {
        width: 1.2em;
        height: 1.2em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.el-divider--horizontal {
  margin: 5px 0;
}
</style>
